import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import { getCrime, updateCrime } from '@/@core/queries/crimes'
import i18n from '@/libs/i18n'
import axios from '@axios'

export default function useCrimeEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const crime = ref({})
  const isSubmitting = ref(false)

  async function show() {
    isSubmitting.value = true
    axios
      .post('/graphql', {
        query: getCrime,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        crime.value = data.data.crime
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.crime_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  function update(data) {
    isSubmitting.value = true
    const payload = {}
    if (data.name?.value) payload.name = data.name.value
    payload.description = data.description?.value || ''

    axios
      .post('/graphql', {
        query: updateCrime,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then(() => {
        showSuccessMessage(i18n.t('message.crime_updated'))
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.crime_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-crime-list' })
        isSubmitting.value = false
      })
  }

  return {
    update,
    show,
    crime,
    isSubmitting,
  }
}
